
import {Component, Mixins, Prop, Vue, Watch} from "vue-property-decorator";
import {mask} from "vue-the-mask";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";

@Component({
  directives: {
    mask
  },
})
export default class TelefonNumarasi extends Mixins(SimpleInputMixin) {
  @Prop() value!: string;
  @Prop() required!: any;
  @Prop() label !: string;

  validate: boolean = false;
  notEmptyRuleText = "Lütfen doldurunuz.";
  notFirstZeroText = "Telefon numarası sıfır İle başlayamaz.";
  localValue = this.value ? this.value : null;

  get mask() {
    if (this.label == "Mobil Telefon") {
      return "(5##) ### ####";
    } else {
      return "(###) ### ####";
    }
  }

  get isRequired() {
    return this.required || this.required === "";
  }
  onPaste(evt:any){
    let val = evt.clipboardData.getData('text');
    val.trim("0");
    while(val.charAt(0) === '0')
    {
      val = val.substring(1);
    }
    this.value = val;
  }

  @Watch('value')
  onValueChange() {
    this.localValue = this.value;
  }

  notEmptyRule() {
    if (this.isRequired) {
      if (this.localValue && this.localValue[1] == "0") {
        return this.notFirstZeroText;
      } else if (!this.localValue || this.localValue.length != 14) {
        return this.notEmptyRuleText;
      }
      return true;
    } else
      return true;
  }
}
